// @flow
import React, { useMemo, useState, useRef } from 'react';
import type { MixedElement } from 'react';
import {
  Box,
  TextField,
  Typography,
  FormControl,
  FormGroup,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Button,
  Alert,
} from '@mui/material';
import { Check as CheckIcon, Redeem as RedeemIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import useReCaptcha from '../../hooks/useReCaptcha';
import isEmpty from '../../utils/isEmpty';

import NameplateOption from '../NameplateOption';
import Heading from '../Heading';
import FormSchema from './validate';

const CheckboxFormControl = styled(FormControl)(({ theme }) => ({
  padding: theme.spacing(1),
  border: '1px solid',
  borderRadius: theme.spacing(0.5),
  borderColor: 'rgba(0, 0, 0, 0.23)',
}));

const SecondItemFormControl = styled(FormControl)(({ theme }) => ({
  padding: theme.spacing(1),
  border: '1px solid',
  borderRadius: theme.spacing(0.5),
  borderColor: 'red',
}));

export default function Form({
  loading = false,
  products,
  deliveries,
  onSubmit,
}: {
  // eslint-disable-next-line react/require-default-props
  loading?: boolean,
  products: Array,
  deliveries: Object,
  onSubmit: Function,
}): MixedElement {
  const { reCaptchaLoaded, generateReCaptchaToken } = useReCaptcha();

  const formRef = useRef();

  const [deliveryType, setDeliveryType] = useState<econt | address>('econt');
  const [nameplate, setNameplate] = useState<string>();
  const [option, setOption] = useState<number>();
  const [hasSecondNameplate, setHasSecondNameplate] = useState<boolean>(false);
  const [secondNameplate, setSecondNameplate] = useState<string>();

  // const [secondOption, setSecondOption] = useState<string>('simple');
  const [errors, setErrors] = useState({
    nameplateText: '',
    nameplateOption: '',
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    secondNameplateText: '',
  });

  const prices = useMemo(() => {
    if (!products) {
      return {
        items: 0,
        total: 0,
        delivery: 0,
      };
    }
    let sumItems = 0;

    if (option) {
      const product = products.find((p) => p.id === option);

      sumItems += product.promoPrice;

      if (hasSecondNameplate) {
        sumItems += product.secondPrice;
      }
    }

    const totalPrice = sumItems + deliveries[deliveryType];

    return {
      items: sumItems.toFixed(2),
      total: totalPrice.toFixed(2),
      delivery: deliveries[deliveryType].toFixed(2),
    };
  }, [option, deliveryType, hasSecondNameplate, products, deliveries]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.elements.name.value,
      email: event.target.elements.email.value,
      address: event.target.elements.address.value,
      phone: event.target.elements.phone.value,
      city: event.target.elements.city.value,
      deliveryType,
      items: [
        {
          product: {
            id: option,
          },
          quantity: 1,
          text: nameplate,
        },
      ],
    };

    if (hasSecondNameplate) {
      formData.items.push({
        product: {
          id: option,
        },
        quantity: 1,
        text: secondNameplate,
      });
    }

    try {
      FormSchema.validateSync(
        {
          ...formData,
          nameplateText: nameplate,
          nameplateOption: option,
          secondNameplateOption: option,
          secondNameplateText: secondNameplate,
          hasSecondNameplate,
        },
        { abortEarly: false },
      );
      const token = await generateReCaptchaToken('order');

      onSubmit({ ...formData, token });
    } catch (e) {
      const formErrors = {};

      // eslint-disable-next-line no-restricted-syntax
      for (const innerError of e.inner) {
        formErrors[innerError.path] = innerError.message;
      }
      setErrors((prev) => ({
        ...prev,
        ...formErrors,
      }));
      formRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const handleValidate = (event) => {
    const { name, value } = event.target;

    try {
      FormSchema.validateSyncAt(name, { [name]: value });
      setErrors((prev) => ({
        ...prev,
        [name]: null,
      }));
    } catch (validationError) {
      setErrors((prev) => ({
        ...prev,
        [name]: validationError.message,
      }));
    }
  };

  let product;

  if (products) {
    product = products.find((p) => p.id === option);
  }

  return (
    <Box
      ref={formRef}
      component="form"
      sx={{
        '& > :not(style)': { m: '8px 0px' },
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      onChange={handleValidate}
    >
      <Heading variant="h5" align="left" color="text.secondary" component="h3">
        Данни за вашата поръчка:
      </Heading>

      <TextField
        name="nameplateText"
        label="Текст на табелката"
        variant="outlined"
        fullWidth
        inputProps={{ 'aria-label': 'Текст на табелката' }}
        onChange={(e) => setNameplate(e.target.value)}
        error={isEmpty(errors.nameplateText)}
      />

      {errors.nameplateText && (
        <FormHelperText error>{errors.nameplateText}</FormHelperText>
      )}

      <NameplateOption
        name="nameplateOption"
        label="Вариант на вашата табелка"
        products={products}
        value={option}
        onChange={(e) => {
          setOption(e.target.value);
        }}
        error={isEmpty(errors.nameplateOption)}
      />

      {errors.nameplateOption && (
        <FormHelperText error>{errors.nameplateOption}</FormHelperText>
      )}

      <Heading variant="h5" align="left" color="text.secondary" component="h3">
        Данни за контакт:
      </Heading>

      <TextField
        name="name"
        label="Име и фамилия"
        inputProps={{ 'aria-label': 'Име и фамилия' }}
        variant="outlined"
        fullWidth
        error={isEmpty(errors.name)}
      />

      {errors.name && <FormHelperText error>{errors.name}</FormHelperText>}

      <TextField
        name="email"
        label="Email Адрес"
        inputProps={{ 'aria-label': 'Email Адрес' }}
        variant="outlined"
        fullWidth
        error={isEmpty(errors.email)}
      />

      {errors.email && <FormHelperText error>{errors.email}</FormHelperText>}

      <TextField
        name="phone"
        label="Телефонен номер"
        inputProps={{ 'aria-label': 'Телефонен номер' }}
        variant="outlined"
        fullWidth
        error={isEmpty(errors.phone)}
      />

      {errors.phone && <FormHelperText error>{errors.phone}</FormHelperText>}

      <TextField
        name="address"
        label="Адрес за доставка"
        inputProps={{ 'aria-label': 'Адрес за доставка' }}
        variant="outlined"
        fullWidth
        error={isEmpty(errors.address)}
      />

      {errors.address && (
        <FormHelperText error>{errors.address}</FormHelperText>
      )}

      <TextField
        name="city"
        label="Град"
        inputProps={{ 'aria-label': 'Град' }}
        variant="outlined"
        fullWidth
        error={isEmpty(errors.city)}
      />

      {errors.city && <FormHelperText error>{errors.city}</FormHelperText>}

      <Heading variant="h5" align="left" color="text.secondary" component="h3">
        Данни за доставка:
      </Heading>

      <CheckboxFormControl fullWidth variant="outlined">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name="econt"
                checked={deliveryType === 'econt'}
                onChange={() => setDeliveryType('econt')}
                inputProps={{ 'aria-label': 'Еконт' }}
              />
            }
            label="Офис на ЕКОНТ"
          />

          <FormHelperText id="my-helper-text">
            Моля, въведете името или адрес на офиса на ЕКОНТ в полето Адрес
            по-горе.
          </FormHelperText>
        </FormGroup>
      </CheckboxFormControl>

      <CheckboxFormControl fullWidth variant="outlined">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name="to_address"
                checked={deliveryType === 'address'}
                onChange={() => setDeliveryType('address')}
                inputProps={{ 'aria-label': 'Адрес' }}
              />
            }
            label="Ваш адрес"
          />
        </FormGroup>
      </CheckboxFormControl>

      <Heading variant="h5" align="left" color="text.secondary" component="h3">
        Метод на плащане:
      </Heading>

      <CheckboxFormControl fullWidth variant="outlined">
        <FormControlLabel
          control={
            <Checkbox
              name="paymentMethod"
              checked
              inputProps={{ 'aria-label': 'Наложен платеж' }}
            />
          }
          label="Наложен платеж"
        />

        <FormHelperText id="my-helper-text">
          Плащането се извършва на куриера при получаване на пратката
        </FormHelperText>
      </CheckboxFormControl>

      <SecondItemFormControl fullWidth variant="outlined">
        <FormControlLabel
          control={
            <Checkbox
              name="oneMore"
              inputProps={{ 'aria-label': 'Направи подарък на притял' }}
            />
          }
          label="Направи подарък на притял"
          checked={hasSecondNameplate}
          onChange={() => setHasSecondNameplate(!hasSecondNameplate)}
        />

        <Alert icon={<RedeemIcon fontSize="inherit" />} severity="error">
          Вземете втора табелка само за {product && product.secondPrice}лв.
        </Alert>
      </SecondItemFormControl>

      {hasSecondNameplate && (
        <>
          <TextField
            label="Текст на втората табелката"
            inputProps={{ 'aria-label': 'Текст на втората табелката' }}
            variant="outlined"
            fullWidth
            onChange={(e) => setSecondNameplate(e.target.value)}
            error={isEmpty(errors.secondNameplateText)}
          />

          {errors.secondNameplateText && (
            <FormHelperText error>{errors.secondNameplateText}</FormHelperText>
          )}

          {/* <NameplateOption
            name="secondNameplateOption"
            label="Вариант на втората табелка"
            value={secondOption}
            onChange={(e) => setSecondOption(e.target.value)}
            error={isEmpty(errors.secondNameplateOption)}
          />

          {errors.secondNameplateOption && (
            <FormHelperText error>
              {errors.secondNameplateOption}
            </FormHelperText>
          )} */}
        </>
      )}

      <Box>
        <Heading
          variant="h5"
          align="left"
          color="text.secondary"
          component="h3"
        >
          Информация за вашата поръчка
        </Heading>

        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
        >
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="stretch"
            >
              {product && (
                <>
                  <Grid item xs={4}>
                    <img src={product.thumb} alt="tabelka" width="100%" />
                  </Grid>

                  <Grid item xs={5}>
                    <Typography variant="body1">
                      Дървена табелка тип {product.name} надпис
                    </Typography>

                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      "{nameplate}"
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      sx={{ textDecoration: 'line-through' }}
                    >
                      {`${product.price} лв.`}
                    </Typography>

                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {`${product.promoPrice} лв.`}
                    </Typography>
                  </Grid>
                </>
              )}

              {hasSecondNameplate && product && (
                <>
                  <Grid item xs={4}>
                    <img src={product.thumb} alt="tabelka" width="100%" />
                  </Grid>

                  <Grid item xs={5}>
                    <Typography variant="body1">
                      Дървена табелка тип {product.name} с надпис
                    </Typography>

                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      "{secondNameplate}"
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    {/* <Typography
                      variant="body2"
                      sx={{ textDecoration: 'line-through' }}
                    >
                      {products[option].price} лв.
                    </Typography> */}

                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {product.secondPrice} лв.
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body1">Междинна сума:</Typography>
          </Grid>

          <Grid item xs={4} align="right">
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {prices.items} лв.
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body1"> Доставка:</Typography>
          </Grid>

          <Grid item xs={4} align="right">
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {prices.delivery} лв.
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body1">Общо:</Typography>
          </Grid>

          <Grid item xs={4} align="right">
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {prices.total} лв.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              sx={{ fontSize: '26px' }}
              variant="contained"
              color="error"
              fullWidth
              disabled={!reCaptchaLoaded || loading}
              size="large"
              type="sumbit"
            >
              Поръчай сега само за {prices.total} лв
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
              Вашите данни са защитени с 256 битово криптиране
            </Alert>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
